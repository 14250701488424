import { useQueryClient } from '@tanstack/vue-query'
import { fromAsyncThrowable } from 'neverthrow'
import { useUserInfoQueryOptions } from './api'

export function useAuthGuard() {
  const router = useRouter()
  const { mutate: logout } = useLogoutMutation()
  router.beforeEach(async (to, _from) => {
    if (to.name === '/login' || to.path.startsWith('/auth/')) {
      return true
    }
    if (!userToken.value) {
      return '/login'
    }

    if (userAddress.value !== null && !!window.deboxWallet?.address) {
      if (userAddress.value !== window.deboxWallet?.address) {
        logout({ replaceRoute: false })
        return '/login'
      }
    }

    if (!!window.deboxWallet?.address && userAddress.value === null) {
      userAddress.value = window.deboxWallet?.address
    }

    const queryClient = useQueryClient()
    const ensureData = fromAsyncThrowable(async () => {
      await queryClient.ensureQueryData(useUserInfoQueryOptions())
    })

    return await ensureData().match(() => {
      return true
    }, () => {
      userToken.value = null
      return '/login'
    })
  })
}
